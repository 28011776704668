<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attributes }">
      <v-btn icon v-bind="attributes" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="$emit('edit', item)">
        <v-list-item-title>
          <v-icon>mdi-pencil</v-icon>
          Edit template
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('delete', item)">
        <v-list-item-title>
          <v-icon>mdi-delete</v-icon>
          Delete template
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
