<template>
  <v-dialog
    :value="isOpened"
    width="620"
    persistent
    style="z-index: 20001"
    class="import-modal"
  >
    <v-card class="pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0 mb-3">
        Create Audit Template
      </v-card-title>
      <v-text-field v-model="templateName" label="Template name" outlined />
      <v-radio-group v-model="auditType" class="mt-0 flex-column">
        <v-row class="mb-4">
          <v-col>
            <v-radio label="Clinical Doc." :value="'Clinical Doc.'" />
          </v-col>
        </v-row>
        <p class="mb-2">Special</p>
        <v-row>
          <v-col>
            <v-radio label="General" :value="'General Audit'" />
          </v-col>
          <v-col>
            <v-radio label="Case-Based" :value="'Special Audit'" class="mb-0" />
          </v-col>
        </v-row>
      </v-radio-group>
      <v-card-actions class="pa-0 pt-6">
        <v-spacer />
        <v-btn color="primary" text @click="$emit('close')">Cancel</v-btn>
        <v-btn
          color="primary"
          rounded
          normal
          variant="elevated"
          :disabled="!templateName || !auditType"
          @click="onCreate"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      templateName: "",
      auditType: "General Audit",
    };
  },
  methods: {
    onCreate() {
      this.$emit("create", this.templateName, this.auditType);
    },
  },
};
</script>
