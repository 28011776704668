<template>
  <section>
    <v-skeleton-loader v-if="initialLoading" :loading="loading" type="table" />
    <v-data-table
      v-if="!initialLoading"
      :loading="loading"
      :headers="headers"
      :items="items"
      :options.sync="tableOptions"
      :server-items-length="itemsTotal"
      :hide-default-footer="$vuetify.breakpoint.mobile"
      :footer-props="tableFooterOptions"
      :mobile-breakpoint="0"
      @click:row="$emit('click:row', $event)"
      @update:options="onTableUpdate"
    >
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="props">
        <slot :name="name" v-bind="props"></slot>
      </template>
      <template #progress>
        <v-progress-linear color="primary" indeterminate />
      </template>
    </v-data-table>

    <v-pagination
      v-if="$vuetify.breakpoint.mobile"
      :value="tableOptions.page"
      :length="paginationLength"
      class="mt-3"
      @input="onPaginationChange"
    />
  </section>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    fetch: {
      type: Function,
      default: () => {},
    },
    onRowClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      initialLoading: true,
      loading: false,
      items: [],
      itemsTotal: 0,
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true,
      },
      tableFooterOptions: {
        "items-per-page-options": [10, 20, 50],
      },
    };
  },
  computed: {
    paginationLength() {
      if (!this.tableOptions?.itemsPerPage) return 0;
      return Math.ceil(this.itemsTotal / this.tableOptions.itemsPerPage);
    },
  },
  async mounted() {
    this.fetchData();
    this.initialLoading = false;
  },
  methods: {
    async fetchData() {
      this.loading = true;

      const { page, itemsPerPage, sortBy, sortDesc } = this.tableOptions;
      const pageSize = itemsPerPage;
      const sort = sortBy.reduce(
        (acc, key, index) => ({ ...acc, [key]: sortDesc[index] ? -1 : 1 }),
        {}
      );

      const { data, total } = await this.fetch({ page, pageSize, sort });
      this.items = data;
      this.itemsTotal = total;

      this.loading = false;
    },
    onTableUpdate(tableOptions) {
      this.tableOptions = tableOptions;
      this.fetchData();
    },
    refresh() {
      this.fetchData();
    },
    onPaginationChange(page) {
      this.tableOptions.page = page;
      this.fetchData();
    },
  },
};
</script>
