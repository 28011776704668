<template>
  <v-dialog
    :value="isOpened"
    width="620"
    persistent
    style="z-index: 20001"
    @click:outside="$emit('close')"
  >
    <v-card class="invite-user-modal pa-5">
      <v-card-title class="text-h5 lighten-2 pa-0">
        {{ title }}
      </v-card-title>

      <v-card-text class="pa-0">
        {{ description }}
      </v-card-text>

      <v-card-actions class="pa-0 pt-8 d-flex justify-end">
        <v-btn color="primary" text normal @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          rounded
          normal
          variant="elevated"
          @click="$emit('submit')"
        >
          {{ submitText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteAuditTemplateModal",

  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Delete audit template",
    },
    description: {
      type: String,
      default: "Are you sure you want to delete an audit template?",
    },
    submitText: {
      type: String,
      default: "Delete",
    },
  },
};
</script>
