<template>
  <section class="d-flex justify-space-between align-center py-4 my-6">
    <div>
      <h1 class="text-h4 font-weight-bold">{{ title }}</h1>
      <p v-if="subtitle" class="text-subtitle-1 text-medium-emphasis">
        {{ subtitle }}
      </p>
    </div>

    <div class="d-flex justify-start align-center">
      <v-btn
        color="primary"
        rounded
        normal
        variant="elevated"
        class="ml-2"
        @click="$emit('click:action')"
      >
        {{ actionLabel }}
      </v-btn>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Page Title",
    },
    subtitle: {
      type: String,
      default: "",
    },
    actionLabel: {
      type: String,
      default: "Create",
    },
  },
};
</script>
