<template>
  <v-main>
    <div class="app-container">
      <PageHeader
        title="Audit Templates"
        action-label="Create template"
        @click:action="toggleCreateModal"
      />

      <DataTable
        ref="auditTemplatesTable"
        :headers="tableHeaders"
        :fetch="getAuditTemplates"
        :is-mobile="$vuetify.breakpoint.mobile"
        @click:row="navigateToTemplate"
      >
        <template #[`item.updatedAt`]="{ item }">
          {{
            item.updatedAt !== item.createdAt
              ? dateToLocalString(item.updatedAt, true)
              : "-"
          }}
        </template>

        <template #[`item.createdAt`]="{ item }">
          {{ dateToLocalString(item.createdAt, true) }}
        </template>

        <template #[`item.actions`]="{ item }">
          <ListContextMenu
            v-if="item.auditType !== BatchTypes.docReview && item.auditType"
            :item="item"
            @edit="navigateToTemplate"
            @delete="selectedTemplate = item"
          />
          <v-tooltip v-else nudge-left left color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-pencil-off-outline</v-icon>
            </template>
            <span>Not editable</span>
          </v-tooltip>
        </template>
      </DataTable>

      <CreateAuditTemplateModal
        :is-opened="isCreateModalOpened"
        @create="onCreateTemplate"
        @close="onCloseCreateModal"
      />

      <DeleteAuditTemplateModal
        :is-opened="!!selectedTemplate"
        @submit="onDeleteTemplate"
        @close="selectedTemplate = null"
      />
    </div>
  </v-main>
</template>

<script>
import {
  getAuditTemplates,
  deleteAuditTemplate,
} from "@/services/auditTemplates";
import dateToLocalString from "@/helpers/dateToLocalString";
import PageHeader from "@/components/common/PageHeader.vue";
import DataTable from "@/components/common/DataTable.vue";
import ListContextMenu from "@/components/auditTemplate/ListContextMenu.vue";
import CreateAuditTemplateModal from "@/components/auditTemplate/CreateAuditTemplateModal.vue";
import DeleteAuditTemplateModal from "@/components/auditTemplate/DeleteAuditTemplateModal.vue";
import { BatchTypes } from "@/misc/constants";

export default {
  name: "AuditTemplatesPage",

  components: {
    PageHeader,
    DataTable,
    ListContextMenu,
    CreateAuditTemplateModal,
    DeleteAuditTemplateModal,
  },

  data() {
    const tableHeaders = [
      {
        text: "Template Name",
        value: "name",
        sortable: true,
      },
      {
        text: "Type",
        value: "auditType",
        sortable: true,
      },
      {
        text: "Last Updated",
        value: "updatedAt",
        sortable: true,
      },
      {
        text: "Create Date",
        value: "createdAt",
        sortable: true,
      },
      {
        text: "",
        align: "end",
        value: "actions",
      },
    ];

    return {
      tableHeaders,
      isCreateModalOpened: false,
      selectedTemplate: false,
      BatchTypes,
    };
  },

  methods: {
    dateToLocalString,
    getAuditTemplates,
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    onCloseCreateModal() {
      this.isCreateModalOpened = false;
    },
    onCreateTemplate(templateName, auditType) {
      this.navigateToTemplate({ _id: "new" }, { templateName, auditType });
    },
    navigateToTemplate({ _id }, query) {
      this.$router.push({ path: `/audit-templates/${_id}`, query });
    },
    async onDeleteTemplate() {
      if (!this.selectedTemplate) return;

      try {
        await deleteAuditTemplate(this.selectedTemplate._id);
      } catch (error) {
        const responseMessage = error?.response?.data?.message;
        if (responseMessage.includes("used audit template")) {
          return this.$notify({ type: "warning", text: responseMessage });
        }
        this.$notify({ type: "error", text: error?.message });
      } finally {
        this.selectedTemplate = null;
        this.$refs.auditTemplatesTable.refresh();
      }
    },
  },
};
</script>
